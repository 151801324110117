<template>
  <div>
    <el-tabs class="mt1 bg-img-tabs" v-model="selectedImgTab">
      <el-tab-pane
        v-for="imgtab in imageTabs"
        :label="imgtab"
        :name="imgtab"
        :key="imgtab"
      >
        <div style="margin-left: -8px;" v-if="imgtab === 'My Images'">
          <my-uploads
            v-if="selectedImgTab === 'My Images'"
            :addImageToCanvasOnClick="false"
            @imgClicked="changeCanvasBackground"
            :uploaderStyles="{
              width: '318px',
              height: '165px',
              left: '8px',
              'background-color': 'transparent',
              'border-color': '1px dashed #999',
            }"
          ></my-uploads>
        </div>
        <div v-if="imgtab === 'Stock Images'" style="margin-left: -10px">
          <stock-images
            v-if="selectedImgTab === 'Stock Images'"
            @imgClicked="changeCanvasBackground"
            :componentMaxWidth="325"
          ></stock-images>
        </div>

        <div v-if="imgtab === 'Image Settings'">
          <el-form @submit.native.prevent ref="form">
            <el-form-item class="mt2" label="Overlay Color">
              <div class="d-flex">
                <extended-el-color-picker
                  show-alpha
                  @change="updateBackgroundOverlay"
                  v-model="backgroundOverlayColor"
                ></extended-el-color-picker>
                <div
                  @click="removeBackgroundOverlay"
                  v-if="backgroundOverlayColor"
                  class="ico-delete-color-overlay-wSdcXp"
                >
                  <IcoBin></IcoBin>
                </div>
              </div>
            </el-form-item>

            <el-form-item class="mt1" label="Background Scale">
              <el-select
                @change="updateBackgroundScale"
                v-model="backgroundScale"
                :allow-create="false"
                :filterable="false"
                placeholder="Background Scale"
                size="mini"
              >
                <el-option
                  v-for="item in backgroundScales"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.value }}</el-option
                >
              </el-select>
            </el-form-item>

            <el-form-item class="mt1" label="Background Position">
              <el-select
                @change="updateBackgroundPosition"
                v-model="backgroundPosition"
                :allow-create="false"
                :filterable="false"
                placeholder="Background Position"
                size="mini"
              >
                <el-option
                  v-for="item in backgroundPositions"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.value }}</el-option
                >
              </el-select>
            </el-form-item>

            <el-checkbox
              class="mt3"
              @change="enableRepeat"
              v-model="backgroundRepeat"
              >Repeat Background</el-checkbox
            >
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import stockImages from "@/components/editor/sidebar/components/graphics//stock-images.component";
import myUploads from "@/components/editor/sidebar/components/graphics/my-uploads.component";
import IcoBin from "@/components/common/ico/delete-24px.svg";

export default {
  name: "canvasImage",
  data: function() {
    return {
      imageTabs: ["My Images", "Stock Images", "Image Settings"],
      selectedImgTab: "My Images",
      images: [],
      backgroundOverlayColor: "",
      backgroundScale: "cover",
      backgroundPosition: "center",
      bScales: ["auto", "100%", "cover", "contain"],
      bPositions: [
        "top left",
        "top center",
        "top right",
        "left",
        "center",
        "right",
        "bottom left",
        "bottom center",
        "bottom right",
      ],
      backgroundRepeat: false,
      supportedFileTypes: ["png", "jpg", "jpeg", "gif", "bmp", "svg"],
    };
  },
  components: {
    "stock-images": stockImages,
    "my-uploads": myUploads,
    IcoBin,
  },
  computed: {
    ...mapGetters("elementsModule", ["canvas"]),
    ...mapActions("elementsModule", ["changeCanvasProperties"]),
    backgroundScales: function() {
      return this.bScales.map((b) => {
        return {
          label: b,
          value: b,
        };
      });
    },
    backgroundPositions: function() {
      return this.bPositions.map((b) => {
        return {
          label: b,
          value: b,
        };
      });
    },
  },
  mounted: function() {
    this.getExistingAttrs();
  },
  watch: {
    activeElementId: function() {
      this.getExistingAttrs();
    },
  },
  methods: {
    getExistingAttrs: function() {
      this.background = this.canvas.background;
      this.backgroundOverlayColor = this.canvas.backgroundOverlayColor;
      this.backgroundPosition = this.canvas.backgroundPosition;
      this.backgroundScale = this.canvas.backgroundSize;
      this.backgroundRepeat = this.canvas.backgroundRepeat !== "no-repeat";
    },
    changeCanvasProp(key, value) {
      this.$store
        .dispatch("elementsModule/changeCanvasProperties", {
          ...this.canvas,
          ...{
            [key]: value,
          },
        })
        .then(() => {
          this.$store.dispatch("elementsModule/changeSaveStateToUnsaved");
        })
        .catch((e) => {
          console.warn(e);
        });
    },
    changeCanvasBackground: function(image) {
      const url = image.image_url ? image.image_url : image.urls.regular;
      this.changeCanvasProp("background", `url(${url}) `);
    },
    updateBackgroundOverlay: function(overlayColor) {
      this.backgroundOverlayColor = overlayColor;
      this.changeCanvasProp("backgroundOverlayColor", overlayColor);
    },
    removeBackgroundOverlay: function() {
      this.backgroundOverlayColor = "";
      this.changeCanvasProp("backgroundOverlayColor", "none");
    },
    updateBackgroundScale: function(value) {
      this.backgroundScale = value;
      this.changeCanvasProp("backgroundSize", value);
    },
    updateBackgroundPosition: function(value) {
      this.backgroundPosition = value;
      this.changeCanvasProp("backgroundPosition", value);
    },
    enableRepeat(value) {
      this.backgroundRepeat = value;
      this.changeCanvasProp("backgroundRepeat", value ? "repeat" : "no-repeat");
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.ico-delete-color-overlay-wSdcXp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
  svg {
    fill: #fff;
  }
}
</style>
