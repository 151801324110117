<template>
  <div class="color-boxes squared-color-boxes-grid">
    <el-dialog
      title="Custom Gradient"
      :visible.sync="showGradientPicker"
      :append-to-body="true"
      width="30%"
    >
      <ColorPicker
        :gradient="gradient"
        :isGradient="true"
        :onStartChange="(color) => onChange(color, 'start')"
        :onChange="(color) => onChange(color, 'change')"
        :onEndChange="(color) => onChange(color, 'end')"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGradientPicker = false">Cancel</el-button>
        <el-button type="primary" @click="applyGradient">Confirm</el-button>
      </span>
    </el-dialog>

    <div
      class="box"
      @click="changeCanvasBackground(gradient)"
      v-for="(gradient, index) in gradients"
      :key="index"
      :style="{ background: gradient }"
    ></div>

    <div
      class="box"
      @click="changeCanvasBackground(gradient)"
      v-for="(gradient, index) in userDefinedGradients"
      :key="index"
      :style="{ background: gradient }"
    ></div>

    <div class="box">
      <el-button
        type="text"
        size="mini"
        @click="toggleGradientPicker"
        class="ml2 btn-add"
      >
        <i class="el-icon-plus"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from "vue-color-gradient-picker";
import("vue-color-gradient-picker/dist/index.css");

export default {
  name: "canvasGradient",
  components: {
    ColorPicker,
  },
  data: function() {
    return {
      gradient: {
        type: "linear",
        degree: 0,
        style: "",
        points: [
          {
            left: 0,
            red: 0,
            green: 0,
            blue: 0,
            alpha: 1,
          },
          {
            left: 100,
            red: 255,
            green: 0,
            blue: 0,
            alpha: 1,
          },
        ],
      },
      showGradientPicker: false,
      userDefinedGradients: [],
      gradientArr: [
        ["#FDEB71", "#F8D800"],
        ["#ABDCFF", "#0396FF"],
        ["#FEB692", "#EA5455"],
        ["#CE9FFC", "#7367F0"],
        ["#90F7EC", "#32CCBC"],
        ["#FFF6B7", "#F6416C"],
        ["#81FBB8", "#28C76F"],
        ["#E2B0FF", "#9F44D3"],
        ["#F97794", "#623AA2"],
        ["#FCCF31", "#F55555"],
        ["#F761A1", "#8C1BAB"],
        ["#5EFCE8", "#736EFE"],
        ["#FAD7A1", "#E96D71"],
        ["#FFD26F", "#3677FF"],
        ["#FEC163", "#DE4313"],
        ["#81FFEF", "#F067B4"],
        ["#FFA8A8", "#FCFF00"],
        ["#FFCF71", "#2376DD"],
        ["#FF96F9", "#C32BAC"],
      ],
      gradients: [],
    };
  },
  computed: {},
  mounted: function() {
    this.gradients = this.gradientArr.map((g) => {
      return `linear-gradient( 135deg, ${g[0]} 10%, ${g[1]}  100%)`;
    });
  },
  methods: {
    changeCanvasBackground: function(color) {
      this.$emit("changeCanvasBackground", color);
    },
    toggleGradientPicker: function() {
      this.showGradientPicker = !this.showGradientPicker;
    },
    onChange: function(attr, name) {
      this.gradient = attr;
    },
    applyGradient: function() {
      this.showGradientPicker = false;
      const newGrad = `${this.gradient.style})`;
      this.$emit("changeCanvasBackground", newGrad);
      this.gradients.push(newGrad);
    },
  },
};
</script>

<style lang="scss"></style>
