<template>
  <div id="background">
    <el-form @submit.native.prevent ref="form">
      <el-tabs
        class="mt1 el-tabs-primary-background"
        v-model="selectedBackgroundType"
      >
        <el-tab-pane v-for="b in backgroundTypes" :label="b" :name="b" :key="b">
          <CanvasColor
            @changeCanvasBackground="changeCanvasBackground"
            v-if="b === 'Color'"
          ></CanvasColor>
          <CanvasGradient
            @changeCanvasBackground="changeCanvasBackground"
            v-if="b === 'Gradient'"
          ></CanvasGradient>
          <CanvasImage
            @changeCanvasBackground="changeCanvasBackground"
            v-if="b === 'Image'"
          ></CanvasImage>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CanvasColor from "./background/color.component";
import CanvasGradient from "./background/gradient.component";
import CanvasImage from "./background/image.component";

export default {
  components: {
    CanvasColor,
    CanvasGradient,
    CanvasImage,
  },
  data() {
    return {
      selectedBackgroundType: "Color",
      backgroundTypes: ["Color", "Gradient", "Image"],
    };
  },

  computed: {
    ...mapActions("elementsModule", ["changeCanvasProperties"]),
    ...mapGetters("elementsModule", ["canvas"]),
  },

  methods: {
    async changeCanvasBackground(background) {
      await this.$store.dispatch("elementsModule/changeCanvasProperties", {
        ...this.canvas,
        ...{
          background,
          backgroundOverlayColor: "",
        },
      });
      await this.$store.dispatch("elementsModule/changeSaveStateToUnsaved");
    },
  },
};
</script>

<style lang="scss" scoped>
.color-palettes {
  .c-box {
    width: 28px;
    height: 28px;
    display: inline-block;
    margin: 3px 2px 2px;
    border-radius: 4px;
  }
}
</style>
