<template>
  <div class="color-boxes squared-color-boxes-grid">
    <div
      class="box"
      @click="changeCanvasBackground(color)"
      v-for="(color, index) in predefineColors"
      :key="getId() + index"
      :style="{ backgroundColor: color }"
    ></div>

    <div
      class="box"
      @click="changeCanvasBackground(color)"
      v-for="(color, index) in colors"
      :key="getId() + index"
      :style="{ backgroundColor: color }"
    ></div>

    <div class="box" style="border-color: transparent">
      <custom-color-picker @change="changeBackground">
        <el-button type="text" size="mini" class="btn-add">
          <i class="el-icon-plus"></i>
        </el-button>
      </custom-color-picker>
    </div>
  </div>
</template>

<script>
import customColorPicker from "@/components/common/custom-color-picker/custom-color-picker.component";
import { mapGetters } from "vuex";
import { generateID } from "@/utils";

export default {
  name: "canvasColor",
  components: {
    "custom-color-picker": customColorPicker,
  },
  computed: {
    ...mapGetters("authModule", ["isAuthenticated", "userInfo"]),
    predefineColors: function() {
      if (this.isAuthenticated) {
        return this.userInfo?.color_palette;
      } else {
        return [];
      }
    },
  },
  data: function() {
    return {
      color: "#fff",
      colors: [
        "#ffffff",
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
      ],
    };
  },
  methods: {
    getId: function() {
      return generateID();
    },
    changeCanvasBackground: function(color) {
      this.$emit("changeCanvasBackground", color);
    },
    changeBackground: function(color) {
      this.colors.push(color);
      this.changeCanvasBackground(color);
    },
  },
};
</script>

<style lang="scss"></style>
